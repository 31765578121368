<template>
  <div class="app-mall-marketing container">
    <div class="content padding-20-0" v-loading="loading">
      <promotion-selector :supplier="supplier" button-text="查看商品" @submit="handleGoodsAdd" />
    </div>
  </div>
</template>

<script>
import promotionSelector from "@/views/assembly/promotionSelect";

export default {
  components: { promotionSelector },
  props: {
    supplier: Number | String
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    handleGoodsAdd(items) {
      if (items && items.length) {
        window.open("/mall/item/" + btoa(items[0].spuId + "_&_mpp"));
      }
    }
  }
};
</script>